import * as React from "react";
import { fetchUtils,Admin, Resource } from 'react-admin';
import { messageList, messageEdit, messageCreate } from './lpaMessages';
import { uomList, uomEdit, uomCreate } from './LpaUom';
import { LookupList, LookupEdit, LookupCreate } from './LpaIndustryStandard';
import { gasketMaterialList , gasketMaterialEdit, gasketMaterialCreate} from './LpaGasketMaterial';
import { connectionSizeList , connectionSizeEdit, connectionSizeCreate} from './LpaConnectionSize';
import { pressureRatingList , pressureRatingEdit, pressureRatingCreate} from './LpaPressureRating';
import { fillFluidTypeList , fillFluidTypeEdit , fillFluidTypeCreate } from "./LpaFillFluidType";
import { fillFluidList , fillFluidEdit , fillFluidCreate } from "./LpaFillFluid.js";
import { addExtensionLengthList , addExtensionLengthEdit, addExtensionLengthCreate} from './LpaAddExtensionLength';
import { extensionLengthList , extensionLengthEdit, extensionLengthCreate} from './LpaExtensionLength';
import { lowerHousingMaterialList , lowerHousingMaterialEdit , lowerHousingMaterialCreate} from './LpaLowerHousingMaterial';
import { extensionDiameterList , extensionDiameterEdit , extensionDiameterCreate} from './LpaExtensionDiameter';
import { diaphragmMaterialList , diaphragmMaterialEdit, diaphragmMaterialCreate} from './LpaDiaphragmMaterial';
import { sealClassList , sealClassEdit , sealClassCreate} from './LpaSealClass';
import { diaphragmCoatingList , diaphragmCoatingEdit , diaphragmCoatingCreate} from './LpaDiaphragmCoating';
import { gasketSurfaceList , gasketSurfaceEdit , gasketSurfaceCreate} from './LpaGasketSurface';
import { ExtensionMaterialList , extensionMaterialEdit , extensionMaterialCreate} from './LpaExtensionMaterial';
import { flushSizeQtyList , flushSizeQtyEdit , flushSizeQtyCreate} from './LpaFlushSizeQty';
import { FlangeMaterialList, flangeMaterialEdit, flangeMaterialCreate} from './LpaFlangeMaterial';
import { diaphragmCompatibilityList , diaphragmCompatibilityEdit , diaphragmCompatibilityCreate} from './LpaDiaphragmCompatibility';
import { upperHousingMaterialList , upperHousingMaterialEdit , upperHousingMaterialCreate} from './LpaUpperHousingMaterial';
import { isolatingDiaphragmList , isolatingDiaphragmEdit , isolatingDiaphragmCreate} from './LpaIsolatingDiaphragm';
import { BoltMaterialList, boltMaterialEdit, boltMaterialCreate} from './LpaBoltMaterial';
import {SecondaryFillFluidList,secondaryFillFluidEdit,secondaryFillFluidCreate} from './LpaSecondaryFillFluid';
import {SystemTypeList,systemTypeEdit,systemTypeCreate} from './LpaSystemType';
import {pressureModuleList , pressureModuleEdit , pressureModuleCreate} from './LpaPressureModule';
import {ConnectionTypeList,connectionTypeEdit,connectionTypeCreate} from './LpaConnectionType';
import {ConnectionLengthList,connectionLengthEdit,connectionLengthCreate} from './LpaConnectionLength';
import {CapillaryOptionsList,capillaryOptionsEdit,capillaryOptionsCreate} from './LpaCapillaryOptions';
import {diaphragmThicknessList,diaphragmThicknessEdit,diaphragmThicknessCreate} from './LpaDiaphgramThickness';
import {designTypeList,designTypeEdit,designTypeCreate} from './LpaDesignType';
import {systemConstructionList,systemConstructionEdit,systemConstructionCreate} from './LpaSystemConstruction';
import {pressureRangeList,pressureRangeEdit,pressureRangeCreate} from './LpaPressureRange';
import {sensorFillFluidList,sensorFillFluidEdit,sensorFillFluidCreate} from './LpaSensorFillFluid';
import {systemDescriptionList,systemDescriptionEdit,systemDescriptionCreate} from './LpaSystemDescription';
import {ReportConfigList,reportConfigEdit,reportConfigCreate} from './ReportConfig';

import TextFieldsIcon from '@material-ui/icons/TextFields';
import authProvider from './authProvider';
import Menu from './Menu';
import jsonServerProvider from 'ra-data-json-server';
import LoginPage from './LoginPage';
import { theme } from "./theme";
import Cookies from 'js-cookie';


const httpClient = (url, options = {}) => {
    const csrftoken = Cookies.get('csrftoken');
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
        options.headers.set('X-CSRFToken', csrftoken)
    }
    if (localStorage.getItem('auth')){
    const { token } = JSON.parse(localStorage.getItem('auth'));
    options.headers.set('Authorization', `Bearer ${token}`);
}
    return fetchUtils.fetchJson(url, options);
};
const api_url = window.location.origin

const dataProvider = jsonServerProvider(api_url, httpClient)

const App = () => (
      <Admin menu={Menu} dataProvider={dataProvider} loginPage={LoginPage} theme={theme} authProvider={authProvider}>
          <Resource name="lpa_messages" options={{ label: 'LPA Messages' }} list={messageList} edit={messageEdit} create={messageCreate} icon={TextFieldsIcon}/>
          <Resource name="lpa_uom" options={{ label: 'LPA UOM' }} list={uomList} edit={uomEdit} create={uomCreate} icon={TextFieldsIcon}/>
          <Resource name="lpa_lookup_admin" options={{ label: 'LPA Industry Standard' }} list={LookupList} edit={LookupEdit} create={LookupCreate} icon={TextFieldsIcon}/>
          <Resource name="gasket_material" options={{ label: 'LPA Gasket Material' }} list={gasketMaterialList}  edit={gasketMaterialEdit} create={gasketMaterialCreate} icon={TextFieldsIcon}/>
          <Resource name="connection_size_admin" options={{ label: 'LPA Connection Size' }} list={connectionSizeList}  edit={connectionSizeEdit} create={connectionSizeCreate} icon={TextFieldsIcon}/>
          <Resource name="fillfluid_type_admin" options={{ label: 'LPA FillFluid Type' }} list={fillFluidTypeList}  edit={fillFluidTypeEdit} create={fillFluidTypeCreate} icon={TextFieldsIcon}/>
          <Resource name="fill_fluid_admin" options={{ label: 'LPA FillFluid' }} list={fillFluidList}  edit={fillFluidEdit} create={fillFluidCreate} icon={TextFieldsIcon}/>
          <Resource name="pressure_rating_admin" options={{ label: 'LPA Pressure Rating' }} list={pressureRatingList}  edit={pressureRatingEdit} create={pressureRatingCreate} icon={TextFieldsIcon}/>
          <Resource name="extension_diameter_admin" options={{ label: 'LPA Extension Diameter' }} list={extensionDiameterList}  edit={extensionDiameterEdit} create={extensionDiameterCreate} icon={TextFieldsIcon}/>
          <Resource name="extension_length_admin" options={{ label: 'LPA Extension Length' }} list={extensionLengthList}  edit={extensionLengthEdit} create={extensionLengthCreate} icon={TextFieldsIcon}/>
          <Resource name="add_extension_length_admin" options={{ label: 'LPA Add Extension Length' }} list={addExtensionLengthList}  edit={addExtensionLengthEdit} create={addExtensionLengthCreate} icon={TextFieldsIcon}/>
          <Resource name="lower_housing_material_admin" options={{ label: 'LPA Lower Housing Material' }} list={lowerHousingMaterialList}  edit={lowerHousingMaterialEdit} create={lowerHousingMaterialCreate} icon={TextFieldsIcon}/>
          <Resource name="seal_class_admin" options={{ label: 'LPA Seal Class' }} list={sealClassList}  edit={sealClassEdit} create={sealClassCreate} icon={TextFieldsIcon}/>
          <Resource name="diaphragm_material_admin" options={{ label: 'LPA Diaphragm Material' }} list={diaphragmMaterialList}  edit={diaphragmMaterialEdit} create={diaphragmMaterialCreate} icon={TextFieldsIcon}/>
          <Resource name="diaphragm_coating_admin" options={{ label: 'LPA Diaphragm Coating' }} list={diaphragmCoatingList}  edit={diaphragmCoatingEdit} create={diaphragmCoatingCreate} icon={TextFieldsIcon}/>
          <Resource name="gasket_surface_admin" options={{ label: 'LPA Gasket Surface' }} list={gasketSurfaceList}  edit={gasketSurfaceEdit} create={gasketSurfaceCreate} icon={TextFieldsIcon}/>
          <Resource name="extension_material_admin" options={{ label: 'LPA Extension Material' }} list={ExtensionMaterialList}  edit={extensionMaterialEdit} create={extensionMaterialCreate} icon={TextFieldsIcon}/>
          <Resource name="flush_size_qty_admin" options={{ label: 'LPA FlushSizeQty' }} list={flushSizeQtyList}  edit={flushSizeQtyEdit} create={flushSizeQtyCreate} icon={TextFieldsIcon}/>
          <Resource name="flange_material_admin" options={{ label: 'LPA Flange Material' }} list={FlangeMaterialList} edit={flangeMaterialEdit} create={flangeMaterialCreate} icon={TextFieldsIcon}/>
          <Resource name="diaphragm_compatibility_admin" options={{ label: 'LPA Diaphragm Compatibility' }} list={diaphragmCompatibilityList}  edit={diaphragmCompatibilityEdit} create={diaphragmCompatibilityCreate} icon={TextFieldsIcon}/>
          <Resource name="upper_housing_material_admin" options={{ label: 'LPA Upper Housing Material' }} list={upperHousingMaterialList}  edit={upperHousingMaterialEdit} create={upperHousingMaterialCreate} icon={TextFieldsIcon}/>
          <Resource name="isolating_diaphragm_admin" options={{ label: 'LPA Isolating Diaphragm' }} list={isolatingDiaphragmList}  edit={isolatingDiaphragmEdit} create={isolatingDiaphragmCreate} icon={TextFieldsIcon}/>
          <Resource name="bolt_material_admin" options={{ label: 'LPA Bolt Material' }} list={BoltMaterialList} edit={boltMaterialEdit} create={boltMaterialCreate} icon={TextFieldsIcon}/>
          <Resource name="secondary_fill_fluid_admin" options={{ label: 'LPA Secondary Fill Fluid' }} list={SecondaryFillFluidList} edit={secondaryFillFluidEdit} create={secondaryFillFluidCreate} icon={TextFieldsIcon}/>
          <Resource name="system_type_admin" options={{ label: 'LPA System Type' }} list={SystemTypeList} edit={systemTypeEdit} create={systemTypeCreate} icon={TextFieldsIcon}/>
          <Resource name="pressure_module_admin" options={{ label: 'LPA Pressure Module' }} list={pressureModuleList} edit={pressureModuleEdit} create={pressureModuleCreate} icon={TextFieldsIcon}/>
          <Resource name="connection_type_admin" options={{ label: 'LPA Connection Type' }} list={ConnectionTypeList} edit={connectionTypeEdit} create={connectionTypeCreate} icon={TextFieldsIcon}/>
          <Resource name="connection_length_admin" options={{ label: 'LPA Connection Length' }} list={ConnectionLengthList} edit={connectionLengthEdit} create={connectionLengthCreate} icon={TextFieldsIcon}/>
          <Resource name="capillary_options_admin" options={{ label: 'LPA Capillary Options' }} list={CapillaryOptionsList} edit={capillaryOptionsEdit} create={capillaryOptionsCreate} icon={TextFieldsIcon}/>
          <Resource name="diaphragm_thickness_admin" options={{ label: 'LPA Diaphragm Thickness' }} list={diaphragmThicknessList} edit={diaphragmThicknessEdit} create={diaphragmThicknessCreate} icon={TextFieldsIcon}/>
          <Resource name="design_type_admin" options={{ label: 'LPA Design Type' }} list={designTypeList} edit={designTypeEdit} create={designTypeCreate} icon={TextFieldsIcon}/>
          <Resource name="system_construction_admin" options={{ label: 'LPA System Construction' }} list={systemConstructionList} edit={systemConstructionEdit} create={systemConstructionCreate} icon={TextFieldsIcon}/>
          <Resource name="pressure_range_admin" options={{ label: 'LPA Pressure Range' }} list={pressureRangeList} edit={pressureRangeEdit} create={pressureRangeCreate} icon={TextFieldsIcon}/>
          <Resource name="sensor_fill_fluid_admin" options={{ label: 'LPA Sensor Fill Fluid' }} list={sensorFillFluidList} edit={sensorFillFluidEdit} create={sensorFillFluidCreate} icon={TextFieldsIcon}/>
          <Resource name="system_description_admin" options={{ label: 'LPA System Description' }} list={systemDescriptionList} edit={systemDescriptionEdit} create={systemDescriptionCreate} icon={TextFieldsIcon}/>
          <Resource name="report_configuration_admin" options={{ label: 'LPA Report Configuration' }} list={ReportConfigList} edit={reportConfigEdit} create={reportConfigCreate} icon={TextFieldsIcon}/>
      </Admin>
  );

export default App;
